import { catchError } from 'rxjs/operators';
import { Injectable, inject } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  router = inject(Router);
  route = inject(ActivatedRoute);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const language: string = localStorage.getItem('language') || 'it';

    request = request.clone({
      headers: request.headers.set('Content-Language', language)
    });

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          const company = this.route.snapshot.paramMap.get('company');
          const shopId = this.route.snapshot.paramMap.get('shopId');
          if (this.router.url.includes('menu')) {
            this.router.navigate(['/expired'], { replaceUrl: true });
            return;
          } if (company && shopId) {
            this.router.navigate([`/${company}/${shopId}`], { replaceUrl: true });
            return;
          } else {
            this.router.navigate(['/error'], { replaceUrl: true });
            return;
          }

        }
        return throwError(() => error)
      })
    );
  }
}
